.sign-in-button {
  background-color: #4285f4;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.sign-in-button:hover {
  background-color: #357ae8;
}

/* Base styles */
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
}

.container {
  width: 500px;
  margin: 0 auto;
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.card-header {
  font-size: 18px;
  text-align: center;
}

.card-body {
  font-size: 16px;
}

/* Home Container */
.home-container {
  background-image: url('./components/Authentication/Illustration.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  width: 70%; /* Adjust width as needed */
  margin: auto; /* Center the box */
  box-shadow: 0 0 10px rgba(0,0,0,0.5); /* Optional shadow for better visibility */
}

/* Login Container */
.login-container {
  background-image: url('./components/Authentication/Illustration.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  width: 50%; /* Adjust width as needed */
  margin: auto; /* Center the box */
  box-shadow: 0 0 10px rgba(0,0,0,0.5); /* Optional shadow for better visibility */
}

/* Specific styles for mobile devices */
@media (max-width: 768px) {
  .home-container, .login-container {
    width: 90%; /* Make the container take most of the width on small screens */
    background-size: cover; /* Adjust background size to cover */
    padding: 10px; /* Adjust padding for mobile */
  }
}

/* Additional styles for login container */
.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
}

.login-container .card {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 20px;
}

.login-container .form-control,
.login-container .btn {
  margin-top: 10px;
}
