.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .signup-card {
    width: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .signup-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .signup-form-label {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .signup-form-input {
    width: 100%;
    height: 40px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  
  .signup-submit-button {
    width: 100%;
    height: 40px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .signup-submit-button:hover {
    background-color: #0056b3;
  }
  